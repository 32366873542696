.accordion-table {
  width: 100%;
  border-collapse: collapse;

  thead th {
    border: 1px dashed black;
    padding: 8px;
    border-top: 0;

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }

  tbody {
    tr {
      td {
        border: 1px dashed black;
        padding: 8px;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }

      &:last-child td {
        border-bottom: 0;
      }
    }
  }
}
