body {
  background-color: #fafafb !important;
}

.link-reset-styles {
  color: inherit;
  text-decoration: inherit;
}

.link-reset-styles:hover {
  color: inherit;
  text-decoration: none;
}
